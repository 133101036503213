import './App.css';
import LandingPage from '../pages/LandingPage/LandingPage';
import { HashRouter, Route, Routes } from "react-router-dom";
import Navbar from '../components/Navbar/Navbar.js';
import AboutPage from "../pages/AboutPage/AboutPage";
import ProjectsPage from "../pages/ProjectsPage/ProjectsPage";
import ContactPage from "../pages/ContactPage/ContactPage";
import GotoPage from "../pages/GotoPage/GotoPage";
import RedirectOldUrls from "../components/RedirectOldUrls/RedirectOldUrls";

function App() {
    return (
        <div className="App">
            <div className={"app-container"}>
                <HashRouter>
                    <RedirectOldUrls /> {/* Add the redirect component here */}
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/projects" element={<ProjectsPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/gotoandplay2024" element={<GotoPage />} />
                        <Route path="*" element={<LandingPage />} />
                    </Routes>
                </HashRouter>
            </div>
        </div>
    )
}

export default App;
