import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectOldUrls() {
    const navigate = useNavigate();

    useEffect(() => {
        const path = window.location.pathname;
        // Only redirect if there is a pathname that does not match the root and no hash is present in the URL
        if (path && path !== "/" && window.location.hash === "") {
            navigate(path, { replace: true });
        }
    }, [navigate]);

    return null;  // This component does not render anything
}

export default RedirectOldUrls;